import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { useLocation } from 'react-router-dom';

import CastDataProvider, { useCastData } from './service/castData';

import Layout from "./components/Layout";
import Enterance from './Enterance';
import Home from './home';
import Allcasts from './Allcasts';
import Newface from './Newface';
import Access from './Access';
import Schedule from './Schedule';
import System from './System';
import Profile from './Profile';
import PcNav from "./components/pcNav";

// css
import "ress";
import './css/theme.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));

function ScrollTop(){
  const path = useLocation()

  console.log(path)

  useEffect(()=>{
    window.scrollTo(0,0)

  },[path])

  return null
}

function App(){
  const {hvnId} = useCastData()
  return(
    <BrowserRouter>
    <ScrollTop />
      <Routes>
        <Route path="/" element={<Enterance />} />
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/system" element={<Layout><PcNav /><System /></Layout>} />
        <Route path="/schedule" element={<Layout><PcNav /><Schedule /></Layout>} />
        <Route path="/schedule/:dateParam" element={<Layout><PcNav /><Schedule /></Layout>} />
        <Route path="/allcasts" element={<Layout><PcNav /><Allcasts /></Layout>} />
        <Route path="/newface" element={<Layout><PcNav /><Newface /></Layout>} />
        <Route path="/profile/:castId" element={
                                                    <Layout hvnId={hvnId}>
                                                      <PcNav />
                                                        <Profile />
                                                    </Layout>
                                                  } />
        <Route path="/access" element={<Layout><PcNav /><Access /></Layout>} />
        <Route path="*"element={<Layout><Home /></Layout>} />
      </Routes>
  </BrowserRouter>    
  )
}

root.render(
  <React.StrictMode>
      <CastDataProvider>
        <App />
      </CastDataProvider>
  </React.StrictMode>,
  document.getElementById("root"),

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
