import { Link } from "react-router-dom"
import { NavLinkMaker } from "../core/core";

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップページ", nameEn: "TOP", path: "/home", blank: false, img: ""},
                        {nameJp: "スケジュール", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: "/system", blank: false, img: ""},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
                        // {nameJp: "写メ日記",     nameEn: "DIARY", path: "/", blank: true, img: ""},
                        // {nameJp: "ムービー",     nameEn: "MOVIE", path: "/", blank: true, img: ""},
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        return(
                            <li key={el.nameEn}>
                                <NavLinkMaker data={el}>
                                    {el.nameJp}<span>{el.nameEn}</span>
                                </NavLinkMaker>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;